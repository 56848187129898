import React, { Component } from 'react'
import Loader from 'react-loaders'
import 'loaders.css/src/animations/pacman.scss'

export default class CustomLoader extends Component {
    render() {
        return (
           <Loader innerClassName={this.props.dark ? "dark" : ""} type="pacman"/>
        )
    }
}
