import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Blockv from "@blockv/sdk";
import { ToastProvider } from 'react-toast-notifications';
import "./App.scss"
import CustomLoader from './Components/Utils/CustomLoader';

const Login = React.lazy(()=>import("./Components/Login/Login"))
const Register = React.lazy(()=>import("./Components/Login/Register"))
const LoginPassword = React.lazy(()=>import("./Components/Login/LoginPassword"))
const LoginSetPassword = React.lazy(()=>import("./Components/Login/LoginSetPassword"))
const Home = React.lazy(()=>import("./Components/Home/Home"))
const Map = React.lazy(()=>import("./Components/Map/Map"))
const Scanner = React.lazy(()=>import("./Components/Scan/Scan"))
const ScanAR = React.lazy(()=>import("./Components/Scan/ScanAR"))
const Profile = React.lazy(()=>import("./Components/Profile/Profile"))
const Vatom = React.lazy(()=>import("./Components/Home/Vatom"))

window.bv = new Blockv({
  appID : "986d3160-aa85-44bf-baed-fa04e072b10b",
  server : "https://api.blockv.io",
  websocketAddress : "wss://newws.blockv.io",
});

export default class App extends Component {
  render() {
    return (
      <Suspense fallback={<div className="loader-container"><CustomLoader/></div>}>
        <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        placement="bottom-center">
        <Router>
          <Switch>
            <Route path="/login/password/set">
              <LoginSetPassword/>
            </Route>
            <Route path="/login/password">
              <LoginPassword/>
            </Route>
            <Route exact path="/register">
              <Register/>
            </Route>
            <Route exact path="/login">
              <Login/>
            </Route>
            <Route path="/loader">
              <LoaderRoute/>
            </Route>
            <ProtectedRoute path="/map">
              <Map/>
            </ProtectedRoute>
            <ProtectedRoute path="/scan">
              <Scanner/>
            </ProtectedRoute>
            <ProtectedRoute path="/scanar">
              <ScanAR/>
            </ProtectedRoute>
            <ProtectedRoute path="/profile">
              <Profile/>
            </ProtectedRoute>
            <ProtectedRoute path="/vatom/:id">
              <Vatom/>
            </ProtectedRoute>
            <ProtectedRoute path="/">
              <Home/>
            </ProtectedRoute>
          </Switch>
        </Router>
        </ToastProvider>
      </Suspense>
    )
  }
}

class ProtectedRoute extends Component{

  constructor(){
    super()
    this.state={
      ready:false
    }
  }

  async componentDidMount(){
    const isLoggedIn = await window.bv.UserManager.isLoggedIn
    this.setState({ready:true,isLoggedIn})
  }

  render(){
    return this.state.ready ?
    this.state.isLoggedIn ?
    React.cloneElement(this.props.children,this.props)
    :
    <Redirect to="/login"/>
    :
    <CustomLoader dark/>
  }
}

class LoaderRoute extends Component{
  render(){
    return (
      <div className="loader-container">
        <CustomLoader dark/>
      </div>
    )
  }
}